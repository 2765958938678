<template>
  <!-- gbwhatsapp.html -->
  <div class="home i_m home_mobile">

    <section id="section-1" class="section-content">
      <img class="write-logo" src="../../../assets/gb/GB_new_icon_small@2x.webp" alt="" />

      <h1 class="write-title" v-html="$global.formatstr(doc.s1.h1, month)"></h1>


      <p class="writter-content">
        If you're looking for a messaging app that combines the best features of WhatsApp and more,
        look no further than GB Whatsapp. This app is a popular third-party mod of WhatsApp that
        offers enhanced functionality and customization options, making it a favorite among millions
        of users worldwide. Whether you're looking for more privacy, more control over your
        messaging experience, or just a way to add some fun and flair to your conversations, GB
        Whatsapp has you covered. So let's dive in and explore all the amazing features that make GB
        Whatsapp the must-have messaging app for anyone looking to take their messaging game to the
        next level!
      </p>

      <lazysource :source="require('@/assets/gb/New_banner@2x.webp')"
        :img="require('@/assets/gb/New_banner@2x.webp')" classname="writer-banner"></lazysource>

      <DownloadBtn class="fix has-exp" v-show="showFixBtn"
        :exp-data="`show_${pageName}home_download`" :name="pageName" :from="from"
        :filename="filename" :isOffical="isOffical" />

      <ApkDetail v-if="pageIntSuccess" :doc="doc" />

      <DownloadBtn class="top-download has-exp" :exp-data="`show_${pageName}home_download`"
        :name="pageName" :from="from" :filename="filename" :isOffical="isOffical" />

      <h2 class="intro-title intro-title-1"><span @click="gotochat()"
          class="cursor-point">GBWhatsApp</span> vs WhatsApp – What's The Difference?</h2>

      <p class="intro-item intro-item-2" v-html="doc.s1.intro_2"></p>

      <table id="playlist" cellspacing="0">
        <tbody>
          <tr>
            <th style="width:29%">Feature</th>
            <th>GBWhatsApp</th>
            <th>WhatsApp</th>
          </tr>
          <tr v-for="(data, i) in feature" :key="i">
            <td>
              <div v-html="data.feature"></div>
            </td>
            <td>
              <div v-html="data.gb"></div>
            </td>
            <td>
              <div v-html="data.whs"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <S2 :doc="doc" />

    <S3 :doc="doc" :pageName="pageName" />

    <DownloadBtn class="top-download has-exp" :exp-data="`show_${pageName}home_download`"
      :name="pageName" :from="from" :filename="filename" :isOffical="isOffical" />

  </div>
</template>

<script>
import "@/css/gb/mobile.scss";
import DownloadBtn from "@/components/DownloadBtnNew.vue";
import S2 from "../assets/section2new.vue";
import S3 from "../assets/section3.vue";
import ApkDetail from "../assets/apkdetail.vue";
import lazysource from "@/components/lazysource.vue";

export default {
  name: "mobile",
  components: {
    DownloadBtn,
    S2,
    S3,
    ApkDetail,
    lazysource
  },
  data () {
    return {
      pageName: "gb",
      doc: null,
      myApp: null,
      from: "gb",
      filename: "gb",
      showFixBtn: true,

      feature: [
        {
          feature: "Hide Online Status",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Airplane Mode",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Status Download",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Add Custom Fonts/Stickers",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Media Sharing",
          gb: "Maximum 200MB",
          whs: "Maximum 15MB",
        },
        {
          feature: "Status Character Length",
          gb: "Maximum 255",
          whs: "Maximum 139",
        },
        {
          feature: "Themes Supported",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "DND Mode",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Freeze Last Seen",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Disable Forwarded Tag",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Disable/Customize Calling",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Anti-Delete Status/Messages",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Security Lock",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Fully Customize",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Auto Reply",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Increase Forward Limit",
          gb: "Maximum 250",
          whs: "Maximum 10",
        },
        {
          feature: "Increase Image Share Limit",
          gb: "Maximum 100",
          whs: "Maximum 30",
        },
      ],

      month: "",
      pageIntSuccess: false,

      showTeleMsg: true,
      isOffical: 0,

    };
  },
  created () {
    this.myApp = this.$store.state.gb;
    this.doc = this.myApp.doc;
  },
  beforeMount () {
    [this.from, this.filename, this.isOffical] = [
      this.$global.GetQueryString("from") || "",
      this.$global.GetQueryString("filename") || "",
      parseInt(this.$global.GetQueryString("isOffical")) || 0,
    ];
    console.log(`from:${this.from},filename:${this.filename}`);

    this.$store.state.activeName = this.myApp.name;
    this.$store.state.activeLogo = this.myApp.logo;

    const monthArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.month = monthArray[new Date().getMonth()];

    console.log(`show_gbhome_${this.$route.name}`);

    this.$logEvent(`show_gbhome_${this.$route.name}`);
  },
  mounted () {
    console.log("mounted");


    setTimeout(() => {
      this.viewHeight = window.innerHeight;
      this.scrollEvent();
      // 监听scroll事件
      this.scrollEventFun = this.$global.throttle(this.scrollEvent, 300);
      window.addEventListener("scroll", this.scrollEventFun, false);

      if (this.$route.params.scrollTo) {
        document
          .querySelector(`#${this.$route.params.scrollTo}`)
          .scrollIntoView({
            behavior: "smooth",
          });
      }
    }, 1000);
  },
  deactivated () {
    window.removeEventListener("scroll", this.scrollEventFun, false);
  },
  beforeDestroy () {
    // 防止内存溢出
    window.removeEventListener("scroll", this.scrollEventFun, false);
  },
  methods: {
    getOffsetTop (element) {
      let actualTop = element.offsetTop;
      let current = element.offsetParent;
      while (current !== null) {
        actualTop += current.offsetTop;
        current = current.offsetParent;
      }
      return actualTop;
    },
    // 获取窗口滚动高度
    scrollTop () {
      return (
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset
      );
    },
    scrollEvent () {
      let $scrollTop = this.scrollTop();
      if ($scrollTop >= 200) {
        document.getElementById("head").classList.add("fix_top_gb");
      }
      if ($scrollTop >= 20) {
        this.showTeleMsg = false;
      }
      console.log(this.getOffsetTop(document.getElementById("footer")));
      // console.log($scrollTop);
      // if ($scrollTop >= 2700) {
      // 	this.showFixBtn = true
      // }
      // if ($scrollTop < 2700) {
      // 	this.showFixBtn = false
      // }
      // if ($scrollTop + 2000 >= this.getOffsetTop(document.getElementById('footer'))) {
      // 	this.showFixBtn = false
      // }
      if ($scrollTop < 200) {
        document.getElementById("head").classList.remove("fix_top_gb");
      }
      let nodeArr = document.querySelectorAll(".has-exp");
      for (let i = 0; i < nodeArr.length; i++) {
        let domTop =
          this.getOffsetTop(nodeArr[i]) +
          nodeArr[i].getBoundingClientRect().height / 3;
        // 当正文部分露出即发此曝光
        let topHeight = this.getOffsetTop(nodeArr[i]) - $scrollTop;
        // console.log('topHeight', topHeight);
        let bottomHeight = domTop - $scrollTop;
        if (topHeight >= 0 && bottomHeight <= this.viewHeight) {
          if (!nodeArr[i].getAttribute("data-exposure")) {
            nodeArr[i].setAttribute("data-exposure", "1");
            const _this = this;
            new Promise((resolve) => {
              resolve();
            }).then(() => {
              let expData = nodeArr[i].getAttribute("exp-data");
              _this.expPost(expData);
            });
          }
        }
        if (topHeight > this.viewHeight) return false;
      }
    },
    expPost (exposeData) {
      this.$logEvent(exposeData);
      console.log(`曝光统计log ${exposeData}`);
    },
    gotoTele () {
      window.location.href = "https://t.me/gb_download";
    },
    gotochat () {
      window.location.href = "https://www.whatsplus.online/";
    },
  },
  metaInfo () {
    return {
      title: `GBWhatsApp APK Download (Anti-Ban) ${this.$root.month} 2024 | Official`,
      script: [
        {
          innerHTML: `
					{
						"@context": "https://schema.org",
						"@type": "SoftwareApplication",
						"name": "GBWhatsApp APK",
						"operatingSystem": "ANDROID",
						"applicationCategory": "LifestyleApplication",
						"aggregateRating": {
							"@type": "AggregateRating",
							"ratingValue": "${this.$store.state.gb.rate}",
							"bestRating": "5",
							"worstRating": "1",
							"ratingCount": "${Math.floor(this.$store.state.gb.clicks / 10.75)}"
						},
						"offers": {
							"@type": "Offer",
							"price": "0",
							"priceCurrency": "USD"
						},
						"review": {
							"@type": "Review",
							"author": {
								"@type": "Person",
								"name": "Hankson"
							},
							"datePublished": "2024-04-16",
							"reviewRating": {
								"@type": "Rating",
								"ratingValue": "${this.$store.state.gb.rate}",
								"bestRating": "5",
								"worstRating": "1"
							}
						}
					}
				`,
          type: "application/ld+json",
        },
      ],
      __dangerouslyDisableSanitizers: ["script"],
      meta: [
        {
          name: "keywords",
          content:
            "gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download",
        },
        {
          name: "image",
          content: `https://cdn.gbwhatsapp.download/img/icon_GB_new@2x.3474f003.webp`,
        },
        {
          name: "description",
          content: `Download GBWhatsApp APK 2024 latest version. It's the BEST way to support OFFICIAL WhatsApp with extra features. Gb WhatsApp is 100% free & Anti-Ban.`,
        },
        {
          name: "og:title",
          content: `GBWhatsApp APK Download (Anti-Ban) ${this.$root.month} 2024 | Official`,
        },
        {
          name: "og:url",
          content: window.location.href,
        },
        {
          name: "og:image",
          content: `https://cdn.gbwhatsapp.download/img/icon_GB_new@2x.3474f003.webp`,
        },
        {
          name: "og:description",
          content: `Download GBWhatsApp APK 2024 latest version. It's the BEST way to support OFFICIAL WhatsApp with extra features. Gb WhatsApp is 100% free & Anti-Ban.`,
        },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0",
        },
      ],
      // link: [
      // 	{
      // 		rel: "canonical",
      // 		href: `https://gbapksdownload.com/gbwhatsapp.html`,
      // 	},
      // ],
    };
  },
};
</script>
